import React from "react";

const DepressionAnxietyInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-xl text-gray-800 mb-2">
          High blood pressure (hypertension)
        </p>
        <p className="text-md text-gray-600 mb-2">
          High blood pressure is a common condition in which the force of the
          blood against your artery walls is high enough that it may eventually
          cause health problems, such as heart disease.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Blood pressure is determined by the amount of blood your heart pumps
          and the amount of resistance to blood flow in your arteries. The more
          blood your heart pumps and the narrower your arteries, the higher your
          blood pressure.
        </p>
        <p className="text-md text-gray-600 mb-2">
          You can have high blood pressure (hypertension) for years without any
          symptoms. Uncontrolled high blood pressure increases your risk of
          serious health problems, including heart attack and stroke.
        </p>
        <p className="text-md text-gray-600 mb-12">
          High blood pressure typically develops over many years, and it affects
          nearly everyone eventually. Fortunately, high blood pressure can be
          easily detected. And once you know you have high blood pressure, you
          can work with your doctor to control it.
        </p>

        <p className="text-xl text-gray-800 mb-2">Symptoms</p>
        <p className="text-md text-gray-600 mb-2">
          Most people with high blood pressure have no signs or symptoms, even
          if blood pressure readings reach dangerously high levels.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Although a few people with early-stage high blood pressure may have
          dull headaches, dizzy spells or a few more nosebleeds than normal,
          these signs and symptoms typically don’t occur until high blood
          pressure has reached a severe — even life-threatening — stage.
        </p>

        <p className="text-xl text-gray-800 mb-2">Causes</p>
        <p className="text-md text-gray-600 mb-3">
          There are two types of high blood pressure.
        </p>
        <p className="text-lg text-gray-700 mb-2">
          Primary (essential) hypertension
        </p>
        <p className="text-md text-gray-600 mb-3">
          For most adults, there’s no identifiable cause of high blood pressure.
          This type of high blood pressure, called essential hypertension or
          primary hypertension, tends to develop gradually over many years.
        </p>
        <p className="text-lg text-gray-700 mb-2">Secondary hypertension</p>
        <p className="text-md text-gray-600 mb-2">
          Some people have high blood pressure caused by an underlying
          condition. This type of high blood pressure, called secondary
          hypertension, tends to appear suddenly and cause higher blood pressure
          than does primary hypertension. Various conditions and medications can
          lead to secondary hypertension, including:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-12">
          <li>Kidney problems</li>
          <li>Adrenal gland tumors</li>
          <li>
            Certain defects in blood vessels you’re born with (congenital)
          </li>
          <li>
            Certain medications, such as birth control pills, cold remedies,
            decongestants, over-the-counter pain relievers and some prescription
            drugs
          </li>
          <li>Illegal drugs, such as cocaine and amphetamines</li>
        </ul>

        <p className="text-xl text-gray-800 mb-2">Risk factors</p>
        <p className="text-md text-gray-600 mb-2">
          High blood pressure has many risk factors, including:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            Age. The risk of high blood pressure increases as you age. Through
            early middle age, high blood pressure is more common in men. Women
            are more likely to develop high blood pressure after menopause.
          </li>
          <li>
            Race. High blood pressure is particularly common among blacks, often
            developing at an earlier age than it does in whites. Serious
            complications, such as stroke and heart attack, also are more common
            in blacks.
          </li>
          <li>Family history. High blood pressure tends to run in families.</li>
          <li>
            Being overweight or obese. The more you weigh, the more blood you
            need to supply oxygen and nutrients to your tissues. As the volume
            of blood circulated through your blood vessels increases, so does
            the pressure on your artery walls.
          </li>
          <li>
            Not being physically active. People who are inactive tend to have
            higher heart rates. The higher your heart rate, the harder your
            heart must work with each contraction — and the stronger the force
            on your arteries. Lack of physical activity also increases the risk
            of being overweight.
          </li>
          <li>
            Using tobacco. Not only does smoking or chewing tobacco immediately
            raise your blood pressure temporarily, but the chemicals in tobacco
            can damage the lining of your artery walls. This can cause your
            arteries to narrow, increasing your blood pressure. Secondhand smoke
            can also increase your blood pressure.
          </li>
          <li>
            Too much salt (sodium) in your diet. Too much sodium in your diet
            can cause your body to retain fluid, which increases blood pressure.
          </li>
          <li>
            Too little potassium in your diet. Potassium helps balance the
            amount of sodium in your cells. If you don’t get enough potassium in
            your diet or retain enough potassium, you may accumulate too much
            sodium in your blood.
          </li>
          <li>
            Too little vitamin D in your diet. It’s uncertain if having too
            little vitamin D in your diet can lead to high blood pressure.
            Vitamin D may affect an enzyme produced by your kidneys that affects
            your blood pressure.
          </li>
          <li>
            Drinking too much alcohol. Over time, heavy drinking can damage your
            heart. Having more than two or three drinks in a sitting can also
            temporarily raise your blood pressure, as it may cause your body to
            release hormones that increase your blood flow and heart rate.
          </li>
          <li>
            Stress. High levels of stress can lead to a temporary, but dramatic,
            increase in blood pressure. If you try to relax by eating more,
            using tobacco or drinking alcohol, you may only increase problems
            with high blood pressure.
          </li>
          <li>
            Certain chronic conditions. Certain chronic conditions also may
            increase your risk of high blood pressure, including high
            cholesterol, diabetes, kidney disease and sleep apnea.
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          Sometimes pregnancy contributes to high blood pressure, as well.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Although high blood pressure is most common in adults, children may be
          at risk, too. For some children, high blood pressure is caused by
          problems with the kidneys or heart. But for a growing number of kids,
          poor lifestyle habits — such as an unhealthy diet and lack of exercise
          — contribute to high blood pressure.
        </p>

        <p className="text-xl text-gray-800 mb-2">Complications</p>
        <p className="text-md text-gray-600 mb-2">
          The excessive pressure on your artery walls caused by high blood
          pressure can damage your blood vessels, as well as organs in your
          body. The higher your blood pressure and the longer it goes
          uncontrolled, the greater the damage.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Uncontrolled high blood pressure can lead to:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-12">
          <li>
            Heart attack or stroke. High blood pressure can cause hardening and
            thickening of the arteries (atherosclerosis), which can lead to a
            heart attack, stroke or other complications.
          </li>
          <li>
            Aneurysm. Increased blood pressure can cause your blood vessels to
            weaken and bulge, forming an aneurysm. If an aneurysm ruptures, it
            can be life-threatening.
          </li>
          <li>
            Heart failure. To pump blood against the higher pressure in your
            vessels, your heart muscle thickens. Eventually, the thickened
            muscle may have a hard time pumping enough blood to meet your body’s
            needs, which can lead to heart failure.
          </li>
          <li>
            Weakened and narrowed blood vessels in your kidneys. This can
            prevent these organs from functioning normally.
          </li>
          <li>
            Thickened, narrowed or torn blood vessels in the eyes. This can
            result in vision loss.
          </li>
          <li>
            Metabolic syndrome. This syndrome is a cluster of disorders of your
            body’s metabolism — including increased waist circumference, high
            triglycerides, low high-density lipoprotein (HDL), or “good,”
            cholesterol, high blood pressure, and high insulin levels. If you
            have high blood pressure, you’re more likely to have other
            components of metabolic syndrome. The more components you have, the
            greater your risk of developing diabetes, heart disease or stroke.
          </li>
          <li>
            Trouble with memory or understanding. Uncontrolled high blood
            pressure also may affect your ability to think, remember and learn.
            Trouble with memory or understanding concepts is more common in
            people who have high blood pressure.
          </li>
        </ul>

        <p className="text-xl text-gray-800 mb-2">Tests and diagnosis</p>
        <p className="text-md text-gray-600 mb-2">
          Blood pressure is measured with an inflatable arm cuff and a
          pressure-measuring gauge. A blood pressure reading, given in
          millimeters of mercury (mm Hg), has two numbers. The first, or upper,
          number measures the pressure in your arteries when your heart beats
          (systolic pressure). The second, or lower, number measures the
          pressure in your arteries between beats (diastolic pressure).
        </p>
        <p className="text-md text-gray-600 mb-2">
          Blood pressure measurements fall into four general categories:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            Normal blood pressure. Your blood pressure is normal if it’s below
            120/80 mm Hg. However, some doctors recommend 115/75 mm Hg as a
            better goal. Once blood pressure rises above 115/75 mm Hg, the risk
            of cardiovascular disease begins to increase.
          </li>
          <li>
            Prehypertension. Prehypertension is a systolic pressure ranging from
            120 to 139 mm Hg or a diastolic pressure ranging from 80 to 89 mm
            Hg. Prehypertension tends to get worse over time.
          </li>
          <li>
            Stage 1 hypertension. Stage 1 hypertension is a systolic pressure
            ranging from 140 to 159 mm Hg or a diastolic pressure ranging from
            90 to 99 mm Hg.
          </li>
          <li>
            Stage 2 hypertension. More severe hypertension, stage 2 hypertension
            is a systolic pressure of 160 mm Hg or higher or a diastolic
            pressure of 100 mm Hg or higher.
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          Both numbers in a blood pressure reading are important. But after age
          50, the systolic reading is even more significant. Isolated systolic
          hypertension (ISH) — when diastolic pressure is normal but systolic
          pressure is high — is the most common type of high blood pressure
          among people older than 50.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Your doctor will likely take two to three blood pressure readings each
          at two or more separate appointments before diagnosing you with high
          blood pressure. This is because blood pressure normally varies
          throughout the day — and sometimes specifically during visits to the
          doctor, a condition called white-coat hypertension. Your doctor may
          ask you to record your blood pressure at home and at work to provide
          additional information.
        </p>
        <p className="text-md text-gray-600 mb-12">
          If you have any type of high blood pressure, your doctor may recommend
          routine tests, such as a urine test (urinalysis), blood tests and an
          electrocardiogram (ECG) — a test that measures your heart’s electrical
          activity. Your doctor may also recommend additional tests, such as a
          cholesterol test, to check for more signs of heart disease.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Taking your blood pressure at home
        </p>
        <p className="text-md text-gray-600 mb-12">
          An important way to check if your blood pressure treatment is working,
          or to diagnose worsening high blood pressure, is to monitor your blood
          pressure at home. Home blood pressure monitors are widely available,
          and you don’t need a prescription to buy one. Talk to your doctor
          about how to get started.
        </p>

        <p className="text-xl text-gray-800 mb-2">Treatments and drugs</p>
        <p className="text-md text-gray-600 mb-2">
          Your blood pressure treatment goal depends on how healthy you are.
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>140/90 mm Hg or lower If you are a healthy adult</li>
          <li>
            130/80 mm Hg or lower If you have chronic kidney disease, diabetes
            or coronary artery disease or are at high risk of coronary artery
            disease
          </li>
          <li>
            120/80 mm Hg or lower If your heart isn’t pumping as well as it
            should (left ventricular dysfunction or heart failure) or you have
            severe chronic kidney disease
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          Although 120/80 mm Hg or lower is the ideal blood pressure goal,
          doctors are unsure if you need treatment (medications) to reach that
          level.
        </p>
        <p className="text-md text-gray-600 mb-2">
          If you’re an adult age 80 or older and your blood pressure is very
          high, your doctor may set a target blood pressure goal for you that’s
          slightly higher than 140/90 mm Hg.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Changing your lifestyle can go a long way toward controlling high
          blood pressure. But sometimes lifestyle changes aren’t enough. In
          addition to diet and exercise, your doctor may recommend medication to
          lower your blood pressure. Which category of medication your doctor
          prescribes depends on your stage of high blood pressure and whether
          you also have other medical problems.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Lifestyle and home remedies
        </p>
        <p className="text-md text-gray-600 mb-2">
          Lifestyle changes can help you control and prevent high blood pressure
          — even if you’re taking blood pressure medication. Here’s what you can
          do:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-12">
          <li>
            Eat healthy foods. Try the Dietary Approaches to Stop Hypertension
            (DASH) diet, which emphasizes fruits, vegetables, whole grains and
            low-fat dairy foods. Get plenty of potassium, which can help prevent
            and control high blood pressure. Eat less saturated fat and total
            fat.
          </li>
          <li>
            Decrease the salt in your diet. A lower sodium level — 1,500
            milligrams (mg) a day — is appropriate for people 51 years of age or
            older, and individuals of any age who are African-American or who
            have hypertension, diabetes or chronic kidney disease. Otherwise
            healthy people can aim for 2,300 mg a day or less. While you can
            reduce the amount of salt you eat by putting down the saltshaker,
            you should also pay attention to the amount of salt that’s in the
            processed foods you eat, such as canned soups or frozen dinners.
          </li>
          <li>
            Maintain a healthy weight. If you’re overweight, losing even 5
            pounds (2.3 kilograms) can lower your blood pressure.
          </li>
          <li>
            Increase physical activity. Regular physical activity can help lower
            your blood pressure and keep your weight under control. Strive for
            at least 30 minutes of physical activity a day.
          </li>
          <li>
            Limit alcohol. Even if you’re healthy, alcohol can raise your blood
            pressure. If you choose to drink alcohol, do so in moderation — up
            to one drink a day for women and everyone over age 65, and two
            drinks a day for men.
          </li>
          <li>
            Don’t smoke. Tobacco injures blood vessel walls and speeds up the
            process of hardening of the arteries. If you smoke, ask your doctor
            to help you quit.
          </li>
          <li>
            Manage stress. Reduce stress as much as possible. Practice healthy
            coping techniques, such as muscle relaxation and deep breathing.
            Getting plenty of sleep can help, too.
          </li>
          <li>
            Monitor your blood pressure at home. Home blood pressure monitoring
            can help you keep closer tabs on your blood pressure, show if
            medication is working, and even alert you and your doctor to
            potential complications. If your blood pressure is under control,
            you may be able to make fewer visits to your doctor if you monitor
            your blood pressure at home.
          </li>
          <li>
            Practice relaxation or slow, deep breathing. Practice taking deep,
            slow breaths to help relax. There are some devices available that
            can help guide your breathing for relaxation; however, it’s
            questionable whether these devices have a significant effect on
            lowering your blood pressure.
          </li>
        </ul>

        <p className="text-xl text-gray-800 mb-2">Alternative medicine</p>
        <p className="text-md text-gray-600 mb-2">
          Although diet and exercise are the best tactics to lower your blood
          pressure, some supplements also may help decrease it. These include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Alpha-linolenic acid (ALA)</li>
          <li>Blond psyllium</li>
          <li>Calcium</li>
          <li>Cocoa</li>
          <li>Cod-liver oil</li>
          <li>Coenzyme Q10</li>
          <li>Omega-3 fatty acids</li>
          <li>Garlic</li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          While it’s best to include these supplements in your diet as foods,
          you can also take supplement pills or capsules. Talk to your doctor
          before adding any of these supplements to your blood pressure
          treatment. Some supplements can interact with medications, causing
          harmful side effects, such as an increased bleeding risk that could be
          fatal.
        </p>
        <p className="text-md text-gray-600 mb-2">
          You can also practice relaxation techniques, such as yoga or deep
          breathing, to help you relax and reduce your stress level. These
          practices can temporarily reduce your blood pressure.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Sticking to lifestyle changes can be difficult — especially if you
          don’t see or feel any symptoms of high blood pressure. If you need
          motivation, remember the risks associated with uncontrolled high blood
          pressure. It may help to enlist the support of your family and friends
          as well.
        </p>
      </div>
    </div>
  );
};

export default DepressionAnxietyInfo;
