import React from "react";
import Layout from "../../../components/Layout";
import PageHeader from "../../../components/PageHeader";
import Conditions from "../../../components/Conditions";
import Hbp from "../../../components/resources/Hbp";

const HighBloodPressure = () => {
  return (
    <Layout>
      <PageHeader text="High Blood Pressure" />
      <Hbp />
      <Conditions />
    </Layout>
  );
};

export default HighBloodPressure;
